import React, { useState } from "react";
import ReactPlayer from "react-player/lazy";
import Image from "next/image";
import { getImageURL } from "../../utils/getImageURL";
import { Lightbox } from "../lightbox";
import { Clock } from "./Clock";
import { Play } from "./Play";
import { VideoThumbProps } from "./types";
import { trackEvent } from "../analytics";

type Props = {
  classNames?: string;
  imgClassNames?: string;
  fill?: boolean;
} & VideoThumbProps;
export function VideoThumb({
  classNames = "w-full max-w-[100%] mx-auto",
  config,
  duration,
  imgClassNames = "",
  posterImage,
  videoURL,
  fill = false,
}: Props) {
  const [isLoadingThumbnail, setIsLoadingThumbnail] = useState(true);
  const [showVideo, setShowVideo] = useState(false);
  const [played, setTimePlayed] = useState(0);

  const openVideo = () => setShowVideo(true);
  const closeVideo = () => {
    trackEvent("video_played", { time_played: played, video_url: videoURL });
    setShowVideo(false);
  };
  const renderPlayerControls = () => setIsLoadingThumbnail(false);

  return (
    <div className={classNames}>
      <div
        className={`relative h-full w-full cursor-pointer`}
        onClick={openVideo}
      >
        {posterImage?.asset && !fill && (
          <Image
            width={0}
            height={0}
            style={{ width: "100%", height: "auto" }}
            sizes="50vw"
            className={`flex w-full rounded-2xl ${imgClassNames}`}
            src={getImageURL(posterImage).url()}
            alt="decorative thumbnail image"
            onLoad={renderPlayerControls}
            quality={90}
          />
        )}

        {posterImage?.asset && fill && (
          <Image
            fill
            className={`${imgClassNames}`}
            src={getImageURL(posterImage).url()}
            alt="decorative thumbnail image"
            onLoad={renderPlayerControls}
          />
        )}

        {!isLoadingThumbnail && (
          <>
            {config?.showDuration && !!duration && (
              <div className="absolute bottom-[20px] left-[20px] flex flex-row items-center rounded-[15px] bg-onyx px-2 py-1 opacity-70">
                <Clock />
                <p className="text-body-4 ml-2 text-white">{duration}</p>
              </div>
            )}

            {config?.showPlayButton && (
              <div className="absolute left-[calc(50%-92px/2)] top-[calc(50%-92px/2)] h-[92px] w-[92px] opacity-35 hover:opacity-100">
                <Play />
              </div>
            )}
          </>
        )}
      </div>

      <Lightbox closeLightbox={closeVideo} hidden={!showVideo}>
        <ReactPlayer
          controls={true}
          url={videoURL}
          playing={showVideo}
          height="100%"
          width="100%"
          className="video-thumb"
          onProgress={(progress) => {
            setTimePlayed(progress.playedSeconds);
          }}
        />
      </Lightbox>
    </div>
  );
}

export default VideoThumb;
