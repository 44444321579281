import { memo } from "react";
import { TypeFromSelection } from "groqd";

import { LinkTo } from "../linkTo";
import { Colorways } from "../../theme";
import { ctaSelection } from "./query";

type CtaProps = TypeFromSelection<typeof ctaSelection>;
type Props = {
  colorway: Colorways;
  CTA: CtaProps;
};
export const Footer = memo(({ colorway, CTA }: Props) => {
  return (
    <>
      <div className="border-t border-lightgray pb-8 pt-8 text-center">
        {!!CTA.url && (
          <LinkTo.External
            url={CTA.url}
            className={`text-head-6 text-${colorway}`}
          >
            <strong>{CTA.text}</strong>
          </LinkTo.External>
        )}
      </div>
    </>
  );
});
Footer.displayName = "Footer";
