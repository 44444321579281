import { ReactNode } from "react";
import { getClassName } from "@pairtreefamily/utils";

export type CardProps = {
  children: ReactNode;
  className?: string;
  hoverable?: boolean;
  backgroundImage?: string;
  depth?: CardDepth;
};

type CardDepth = "flat" | "low" | "medium" | "high";

function getDepthClass(depth?: CardDepth) {
  switch (depth) {
    case "low":
      return "shadow-md hover:shadow-lg";
    case "medium":
      return "shadow-lg hover:shadow-xl";
    case "high":
      return "shadow-xl hover:shadow-2xl";
    case "flat":
    default:
      return false;
  }
}

export function Card(props: CardProps) {
  const className = getClassName(
    "flex flex-col",
    "rounded-2xl",
    getDepthClass(props.depth),
    props.hoverable && "hover:cursor-pointer hover:shadow-lg",
    props.className,
  );

  return (
    <div
      style={
        props.backgroundImage
          ? { backgroundImage: `url(${props.backgroundImage})` }
          : undefined
      }
      className={className}
    >
      {props.children}
    </div>
  );
}

export default Card;
