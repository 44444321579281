import { CarouselProvider, Slide, Slider } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

import Block from "../block";
import { Colorways } from "src/theme";
import { VideoHighlightType } from "./types";
import { Highlight } from "./Highlight";
import { Tabs } from "./Tabs";

type Props = { colorway: Colorways } & VideoHighlightType;
export function VideoHighlight({
  colorway,
  config,
  highlights,
  overline,
}: Props) {
  const totalSlides = highlights.length;
  const textColor = `text-${colorway}`;

  return (
    <CarouselProvider
      naturalSlideWidth={0}
      naturalSlideHeight={0}
      totalSlides={totalSlides}
      currentSlide={0}
      infinite={true}
      interval={config.animationInterval}
      isPlaying={true}
    >
      <Block flushBottom>
        <div className="grid">
          <div className="hidden lg:grid lg:grid-cols-[50%,50%]">
            {!!overline && (
              <p className={`text-overline-2 ${textColor} text-center`}>
                {overline}
              </p>
            )}
          </div>

          <Slider className="order-last lg:order-1">
            {highlights.map((highlight, i) => (
              <Slide index={i} key={i}>
                <Highlight
                  animationInterval={config.animationInterval}
                  colorway={colorway}
                  heading={highlight.heading}
                  intro={highlight.intro}
                  key={i}
                  totalSlides={totalSlides}
                  video={highlight.video}
                />
              </Slide>
            ))}
          </Slider>

          <div className="order-1 grid grid-cols-[100%,0%] lg:order-last lg:grid-cols-[50%,50%]">
            <div className="flex flex-col items-center justify-center">
              {!!overline && (
                <p
                  className={`text-overline-2 lg:hidden ${textColor} text-center`}
                >
                  {overline}
                </p>
              )}

              {totalSlides > 1 && (
                <Tabs
                  animationInterval={config.animationInterval}
                  colorway={colorway}
                  totalSlides={totalSlides}
                />
              )}
            </div>
          </div>
        </div>
      </Block>
    </CarouselProvider>
  );
}

export default VideoHighlight;
