import { q, z } from "groqd";

import { imageSelection } from "@/selections/image-selection";
import { sanityInternalsSelection } from "@/selections/sanity-internals-selection";
import { sectionIntroSelection } from "../SectionIntro";

export const ctaSelection = {
  text1: z.nullable(q.string().optional()),
  text2: z.nullable(q.string().optional()),
  url: z.nullable(q.string().optional()),
};

export const accordionItemSelection = {
  body: q.contentBlocks(),
  title: q.string(),
  _id: z.nullable(q.string().optional()), // NOTE: for internal use only, not presented on CMS.
  _isExpanded: z.nullable(q.boolean().optional()), // NOTE: for internal use only, not presented on CMS.
};

export const singleImageAccordionSelection = {
  ...sanityInternalsSelection,
  sectionIntro: z.nullable(q.object(sectionIntroSelection)),
  accordionItems: q.array(q.object(accordionItemSelection)),
  intro: q.contentBlocks(),
  CTA: z.nullable(q.object(ctaSelection).optional()),
  imageOrVideo: q.object(imageSelection),
  showIterator: q.boolean(),
};
