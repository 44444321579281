import { Tabs } from "./Tabs";
import { Slides } from "./Slides";
import { Block } from "../../block";
import { Colorways } from "../../../theme";
import { CarouselTabs } from "../types";
import { isEmpty } from "../../../utils/isEmpty";
import { SectionIntroType, SectionIntro } from "../../SectionIntro";

type SlidingCarouselProps = {
  animationInterval: number;
  carouselTabs: CarouselTabs;
  colorway: Colorways;
  sectionIntro: SectionIntroType | null;
};
export const SlidingCarousel = ({
  animationInterval,
  carouselTabs,
  colorway,
  sectionIntro,
}: SlidingCarouselProps) => {
  if (isEmpty(carouselTabs)) return null;

  return (
    <Block>
      {sectionIntro?.shouldDisplay && (
        <SectionIntro
          colorway={colorway}
          heading={sectionIntro.heading}
          intro={sectionIntro.intro}
          overline={sectionIntro.overline}
          shouldDisplay={sectionIntro.shouldDisplay}
        />
      )}

      <Tabs
        animationInterval={animationInterval}
        carouselTabs={carouselTabs}
        colorway={colorway}
      />

      <Slides carouselTabs={carouselTabs} colorway={colorway} />
    </Block>
  );
};
