import { memo } from "react";
import { InferType, TypeFromSelection } from "groqd";
import { trackEvent } from "../analytics";
import { ctaSchema, ctaSelection } from "./query";
import { Colorways } from "../../theme";
import { Block } from "../block";
import Link from "next/link";

type Props = TypeFromSelection<typeof ctaSelection> & { colorway: Colorways };
export type CTAProps = InferType<typeof ctaSchema>;
export const CTABlock = memo(
  ({ colorway = Colorways.Default, copy, heading, url }: Props) => {
    const bg = `bg-${colorway}`;

    return (
      <div className="flex max-w-xs flex-col text-center">
        <p className="text-head-5 mb-6 text-onyx">
          <em>{heading}</em>
        </p>
        <p className="text-lg mb-12 text-onyx-light">{copy}</p>
        <Link
          className={`${bg} rollover-animation mx-auto flex h-16 w-16 items-center justify-center rounded-full text-heading-45 font-[300] text-white`}
          href={url}
          onClick={() =>
            trackEvent("cta_clicked", { cta: heading, cta_url: url })
          }
        >
          →
        </Link>
      </div>
    );
  },
);

CTABlock.displayName = "CTABlock";

type CTABlocksProps = { colorway: Colorways; CTABlocksGroup: CTAProps };
export default function CTABlocks({
  colorway,
  CTABlocksGroup,
}: CTABlocksProps) {
  return (
    <Block>
      <div className="flex flex-col items-center justify-center max-sm:space-y-28 lg:flex-row lg:items-center lg:space-x-28">
        {CTABlocksGroup.map((cta) => (
          <CTABlock key={cta._key} colorway={colorway} {...cta} />
        ))}
      </div>
    </Block>
  );
}

CTABlocks.displayName = "CTABlocks";
