import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { CarouselContext } from "pure-react-carousel";

import { Colorways } from "../../../theme";
import { CarouselTab, CarouselTabs } from "../types";
import { isEmpty } from "../../../utils/isEmpty";

const TAB_ANIMATION_TRANSITION_PROP = "width";
const TAB_ANIMATION_START_PROPS = "w-0";
const TAB_ANIMATION_END_PROPS = "w-full";
type TabProps = {
  animationInterval: number;
  colorway: Colorways;
  index: number;
  title: string;
};
const Tab = ({
  colorway,
  index = 0,
  animationInterval,
  title = "",
}: TabProps) => {
  const carouselContext = useContext(CarouselContext);

  // states
  /////
  const [currentSlide, setCurrentSlide] = useState(
    carouselContext.state.currentSlide,
  );
  const [animation, setAnimation] = useState(TAB_ANIMATION_START_PROPS);

  // memos
  /////
  const isActive = useMemo(() => currentSlide === index, [currentSlide, index]);
  const number = useMemo(
    () => (index < 10 ? `0${index + 1}` : (index + 1).toString()),
    [index],
  );

  // memos: css
  /////
  const border = "border-b-solid border-b-[2px] border-platinum rounded-[2px]";
  const animatedBorder = useMemo(
    () =>
      `${
        isActive
          ? `rounded-[2px] border-b-[2px] block border-${colorway} mt-[-2px]`
          : ""
      }`,
    [colorway, isActive],
  );
  const textColor = useMemo(
    () => (isActive ? `text-${colorway}` : `text-quicksilver`),
    [colorway, isActive],
  );
  // Tailwind doesn't allow to generate classes dynamically.
  // Meaning that `duration-[${animationInterval}]` (being `duration-[]` a shorthand for an arbitrary value) wouldn't be understood by Tailwind.
  // Although `border-${colorway}` is understood because it is _already defined_ on a css file.
  // Thus, to enable user defined animation interval, the best option is to rely on css styles.
  const transitionStyles = useMemo(
    () => ({
      transitionTimingFunction: "linear",
      transitionProperty: isActive ? TAB_ANIMATION_TRANSITION_PROP : "none",
      transitionDuration: isActive ? `${animationInterval}ms` : "0",
    }),
    [animationInterval, isActive],
  );

  // callbacks
  /////
  const goToSlide = useCallback(
    () => carouselContext.setStoreState({ currentSlide: index }),
    [carouselContext, index],
  );

  // effects
  /////
  // Tells to component which is the current slide the carousel is displaying.
  useEffect(() => {
    function onChange() {
      setCurrentSlide(carouselContext.state.currentSlide);
    }

    carouselContext.subscribe(onChange);
    return () => carouselContext.unsubscribe(onChange);
  }, [carouselContext]);

  // Triggers the progressive border animation if the tab is active.
  // Restart the animation initial definition settings upon tab change.
  useEffect(() => {
    if (isActive) setAnimation(TAB_ANIMATION_END_PROPS);
    return () => setAnimation(TAB_ANIMATION_START_PROPS);
  }, [isActive]);

  return (
    <div className="flex grow flex-col">
      <div
        className={`flex grow cursor-pointer flex-col justify-between pb-4 ${border}`}
        onClick={goToSlide}
      >
        <div className={`w-full text-onyx`}>
          <p className={`text-body-3 ${textColor}`}>{number}</p>
          <p className="text-head-8">{title}</p>
        </div>
      </div>

      <div
        className={`${animatedBorder} ${animation}`}
        style={transitionStyles}
      />
    </div>
  );
};

type TabsProps = {
  animationInterval: number;
  carouselTabs: CarouselTabs;
  colorway: Colorways;
};
export const Tabs = ({
  animationInterval,
  carouselTabs,
  colorway,
}: TabsProps) => {
  if (isEmpty(carouselTabs)) return null;

  const tabCount = carouselTabs.length;

  return (
    <div
      className={`${
        tabCount === 5 ? "xl:w-5/6" : "xl:w-3/4"
      } mx-auto grid auto-cols-fr grid-flow-col gap-x-4`}
    >
      {carouselTabs.map((tab, i) => (
        <Tab
          animationInterval={animationInterval}
          colorway={colorway}
          index={i}
          key={i}
          title={tab.title}
        />
      ))}
    </div>
  );
};
