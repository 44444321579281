import { q, z } from "groqd";

import { sectionIntroSelection } from "../SectionIntro";
import { ctaButtonSelection } from "../CtaButton";
import { sanityInternalsSelection } from "@/selections/sanity-internals-selection";
import { Colorways } from "src/theme";

export const ribbonSelection = {
  shouldDisplay: z.nullable(q.boolean()),
};

export const pricingOptionSelection = {
  _id: z.nullable(q.string().optional()), // NOTE: REQUIRED FOR INTERNAL USAGE ONLY, SEE "PricingPlans.tsx"
  _isVisible: z.nullable(q.boolean().optional()), // NOTE: REQUIRED FOR INTERNAL USAGE ONLY, SEE "PricingPlans.tsx"

  headline: z.nullable(
    q.object({
      highlighted: z.nullable(q.string().optional()), // "$83"
      asideTitle: z.nullable(q.string().optional()), // "/ month"
      asideSubtitle: z.nullable(q.string().optional()), // "billed annually at $999"
    }),
  ),

  label: z.nullable(q.string().optional()), // "12 Months"

  benefits: z.nullable(
    q.array(
      q.object({
        displayCheckMark: q.boolean(), // ✓
        heading: z.nullable(q.string().optional()), // "plus"
        firstLine: z.nullable(
          q
            .object({
              size: z.nullable(q.string().optional()), // "text-body-4" or "text-body-2"
              text: z.nullable(q.string().optional()), // "Custom, easy-to-create profile videos"
            })
            .optional(),
        ),
        secondLine: z.nullable(q.string().optional()), // "Easily download and share for additional outreach!"
      }),
    ),
  ),

  shouldDisplay: z.nullable(q.boolean().optional()),
};

export const pricingPlanSelection = {
  ...sanityInternalsSelection,
  title: z.nullable(q.string().optional()),
  subtitle: z.nullable(q.string().optional()),
  colorway: z.nativeEnum(Colorways), // NOTE: will override the value from modular page, if existing

  ribbon: z.nullable(q.object(ribbonSelection)),
  pricingOptions: z.nullable(q.array(q.object(pricingOptionSelection))),
  pricingOptionsCallout: z.nullable(
    q.object({
      shouldDisplay: z.nullable(q.boolean()),
      text: z.nullable(q.string().optional()), // "<- Profile video included in 12-month subscriptions!"
    }),
  ),

  ctaButton1: z.nullable(q.object(ctaButtonSelection).optional()), // "Get Started"
  ctaButton2: z.nullable(q.object(ctaButtonSelection).optional()), // "See Full Feature List"
};

export const pricingPlansSelection = {
  ...sanityInternalsSelection,
  sectionIntro: z.nullable(q.object(sectionIntroSelection)),
  pricingPlans: z.nullable(q.array(q.object(pricingPlanSelection))),
};
