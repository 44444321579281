import { isEmpty } from "lodash";

import { FeaturedArticlesType } from "./types";
import { Block } from "../block";
import { Colorways } from "src/theme";
import { CtaButton } from "../CtaButton";
import { ArticleCard } from "./ArticleCard";

type FeaturedArticlesProps = FeaturedArticlesType & { colorway: Colorways };
export default function FeaturedArticles({
  body,
  colorway,
  ctaButton,
  heading,
  overline,
  featuredArticles,
}: FeaturedArticlesProps) {
  return (
    <Block>
      <div className="grid gap-4 md:grid-cols-2 xl:grid-cols-4">
        <div className="flex flex-col gap-y-4 pr-2 ">
          {!!overline && (
            <div className={`${colorway}-colorway`}>
              <p className="text-overline-2">{overline}</p>
            </div>
          )}

          {!!heading && (
            <h2 className={`text-deco-head-3 text-onyx`}>
              <strong>
                <em>{heading}</em>
              </strong>
            </h2>
          )}

          {!!body && <p className="text-body-2 text-gray">{body}</p>}

          {!!ctaButton && ctaButton.shouldDisplay && (
            <div className="hidden lg:block ">
              <CtaButton {...ctaButton} colorway={colorway} />
            </div>
          )}
        </div>

        {!isEmpty(featuredArticles) &&
          featuredArticles.map((ac, i) => <ArticleCard {...ac} key={i} />)}

        {!!ctaButton && ctaButton.shouldDisplay && (
          <div className="mt-4 block lg:hidden">
            <CtaButton
              {...ctaButton}
              colorway={colorway}
              classNames="sm:w-full md:w-full"
            />
          </div>
        )}
      </div>
    </Block>
  );
}

FeaturedArticles.displayName = "FeaturedArticles";
