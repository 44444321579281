import { isEmpty } from "lodash";

import { Colorways } from "src/theme";
import { RichTextSimpleComponent } from "../RichTextSimpleComponent";
import { HighlightType } from "./types";
import { VideoThumb } from "../VideoThumb";

type Props = {
  animationInterval: number;
  colorway: Colorways;
  totalSlides: number;
} & HighlightType;
export const Highlight = ({ colorway, heading, intro, video }: Props) => {
  return (
    <div className="m-auto flex w-full flex-col lg:max-w-full lg:flex-row lg:items-start">
      <div className="align-center flex flex-col lg:w-[50%] lg:px-16">
        <div className="text-pretty text-center">
          {!!heading && (
            <h3 className="text-deco-head-2 text-dark my-6">{heading}</h3>
          )}

          {!!intro && (
            <RichTextSimpleComponent colorway={colorway} content={intro} />
          )}
        </div>
      </div>

      <div className="mt-8 lg:w-[50%] lg:flex-1">
        {!isEmpty(video) && (
          <VideoThumb
            config={video.config}
            duration={video.duration}
            posterImage={video.posterImage}
            videoURL={video.videoURL}
          />
        )}
      </div>
    </div>
  );
};
