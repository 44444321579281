import { HubspotProvider, useHubspotForm } from "next-hubspot";
import Image from "next/image";
import { isEmpty } from "lodash";

import { RichTextSimpleComponent } from "../RichTextSimpleComponent";
import { HeroWithFormType } from "./types";
import { LayoutVariant } from "./enums";
import QuickLinks from "../QuickLinks/QuickLinks";
import { Block } from "../block";
import { kebabCase } from "@/utils/kebabCase";
import { useEffect } from "react";
import { trackEvent } from "../analytics";

const HUBSPOT_FORM_ID = "hubspot-form-wrapper";

const LAYOUT_VARIANT_MAP = {
  [LayoutVariant.Default]: ``,
  [LayoutVariant.Transparent]: `variant-bg-transparent`,
};

const FORM_STYLING_MAP = {
  [LayoutVariant.Default]:
    "border border-solid border-lightgray bg-backgroundgray",
  [LayoutVariant.Transparent]: "border-0 bg-transparent",
};

const FORM_HEADING_STYLING_MAP = {
  [LayoutVariant.Default]: "",
  [LayoutVariant.Transparent]: "text-white",
};

function HeroWithHubspotForm({
  colorway,
  formHeading,
  formId,
  intro,
  quickLinks,
  heroLinkAnchor,
  config,
}: HeroWithFormType) {
  const targetId = `${HUBSPOT_FORM_ID}_${formId}`;
  const target = `#${targetId}`;

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: "14522431",
          formId: formId,
          target: target,
          onFormSubmit: ($form) => {
            trackEvent("form_submitted", { form: formId });
          },
        });
      }
      return () => {
        window.removeEventListener("load", () => {});
      };
    });
  }, []);

  const variant = LAYOUT_VARIANT_MAP[config.layoutVariant];
  const formStyling = FORM_STYLING_MAP[config.layoutVariant];
  const formHeadingStyling = FORM_HEADING_STYLING_MAP[config.layoutVariant];

  return (
    <>
      <Block
        fullWidth
        flushBottom
        flushTop
        className={`bg-${colorway}-gradient relative`}
      >
        <div className="absolute bottom-0 left-0 z-0">
          <Image
            src="/hero-vector-overlay.png"
            width={0}
            height={0}
            style={{ width: "100%" }}
            className={`flex w-full object-cover`}
            alt="hero"
          />
        </div>

        <div className="container mx-auto">
          <div
            id={kebabCase(heroLinkAnchor) ?? `form-${formId}`}
            className={`grid w-full grid-cols-none grid-rows-[auto,auto] gap-4 px-6 py-12 md:px-16 lg:grid-cols-2 lg:grid-rows-none lg:gap-8 `}
          >
            <div className="text-white-override my-auto text-center">
              {!!intro && (
                <div className="relative z-10 mx-auto lg:w-5/6">
                  <RichTextSimpleComponent
                    content={intro}
                    colorway={colorway}
                  />
                </div>
              )}
            </div>

            <div
              className={`z-10 mx-auto rounded-lg px-6 py-8 drop-shadow-md ${formStyling} "w-full max-w-[480px]" }`}
            >
              {!!formHeading && (
                <h6 className={`text-head-6 mb-4 ${formHeadingStyling}`}>
                  {formHeading}
                </h6>
              )}

              <div
                id={targetId}
                className={`${HUBSPOT_FORM_ID} bg-${colorway} ${variant}`}
              />
            </div>
          </div>
        </div>
      </Block>
      {!isEmpty(quickLinks) && quickLinks?.config?.showQuickLinks && (
        <QuickLinks {...quickLinks} colorway={colorway} />
      )}
    </>
  );
}

export default function HeroWithForm(props: HeroWithFormType) {
  return <HeroWithHubspotForm {...props} />;
}
