import Image from "next/image";
import { RichTextSimpleComponent } from "../RichTextSimpleComponent";
import { Block } from "../block";
import { CTAGridType } from "./types";
import { getImageURL } from "../../utils/getImageURL";
import { Colorways } from "../../theme";
import {
  getHeightCorrected,
  getWidthCorrected,
} from "@/utils/getImageDimensions";
type Props = { colorway: Colorways } & CTAGridType;

export default function CTAGrid({ colorway, cols }: Props) {
  return (
    <Block>
      <div className="grid grid-cols-none grid-rows-[repeat(3,min-content)] items-center gap-4 text-center lg:grid-cols-3 lg:grid-rows-none lg:gap-0">
        {cols.map((col, i) => (
          <div className="flex flex-col lg:gap-2" key={i}>
            {!!col.image?.asset && (
              <Image
                width={getWidthCorrected(col.image)}
                height={getHeightCorrected(col.image)}
                style={{ width: "100%", height: "auto", margin: "0 auto" }}
                className="flex w-full max-w-[115px] justify-center rounded-full object-cover"
                src={getImageURL(col.image).url()}
                alt="multi column section image"
              />
            )}

            {!!col.text && (
              <RichTextSimpleComponent colorway={colorway} content={col.text} />
            )}
          </div>
        ))}
      </div>
    </Block>
  );
}
