import { q, z } from "groqd";

import { sanityInternalsSelection } from "@/selections/sanity-internals-selection";
import { videoThumbSelection } from "../VideoThumb";

export const videoSelection = {
  title: q.string(), // { "videos": videos[]{title} }
  description: q.string(), // { "videos": videos[]{title,description} }
  video: q("video") // { "videos": videos[]{title,description, video->} }
    .deref() // { "videos": videos[]{title,description, video->} }
    .grab(videoThumbSelection) // { "videos": videos[]{title,description, video->{duration, videoURL, ...}} }
    .nullable(),
};

export const videoGridSelection = {
  ...sanityInternalsSelection,
  videos: q("videos") // { videos }
    .filter() // { "videos": videos[] }
    .grab(videoSelection), // { "videos": videos[]{} }
};
