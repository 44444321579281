import { q, z } from "groqd";

import { sectionIntroSelection } from "../SectionIntro";
import { ctaButtonSelection } from "../CtaButton";
import {
  Backgrounds,
  HeadingStyle,
  ImagePlacement,
  LayoutVariant,
} from "./enums";
import { sanityInternalsSelection } from "@/selections/sanity-internals-selection";
import { imageSelection } from "@/selections/image-selection";

export const featureBlockSelection = {
  heading: z.nullable(q.string().optional()),
  body: z.nullable(q.contentBlocks().optional()),
};

export const benefitsSelection = {
  pre: z.nullable(q.string().optional()),
  heading: z.nullable(q.string().optional()),
  benefits: z.nullable(q.array(q.string())),
};

export const multiColumnSectionSelection = {
  ...sanityInternalsSelection,
  sectionIntro: z.nullable(q.object(sectionIntroSelection)),
  image: q.object(imageSelection),
  overline: z.nullable(z.string()),
  heading: z.nullable(z.string()),
  intro: z.nullable(q.contentBlocks()),
  featureBlocks: z.nullable(q.array(q.object(featureBlockSelection))),
  benefitsBlock: z.nullable(q.object(benefitsSelection).optional()),
  primaryCTA: q.object(ctaButtonSelection),
  secondaryCTA: q.object(ctaButtonSelection),
  config: q.object({
    background: z.nativeEnum(Backgrounds),
    imagePlacement: z.nativeEnum(ImagePlacement),
    headingStyle: z.nativeEnum(HeadingStyle),
    layoutVariant: z.nativeEnum(LayoutVariant),
  }),
};
