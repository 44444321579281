import { useCallback, useMemo, useState } from "react";
import { TypeFromSelection } from "groqd";

import { Block } from "../block";
import { Colorways } from "../../theme";
import { FAQAccordionSelection } from "./query";
import { RichTextSimpleComponent } from "../RichTextSimpleComponent";
import { FAQAccordionItem } from "./FAQAccordionItem";
import { Footer } from "./Footer";
import { SectionIntro } from "../SectionIntro";
import { uuid } from "../../utils/uuid";
import { isEmpty } from "lodash";
import { AccordionItemType } from "./types";

type Props = TypeFromSelection<typeof FAQAccordionSelection> & {
  colorway: Colorways;
};
export default function FAQAccordion({
  accordionItems,
  colorway = Colorways.Default,
  CTA,
  intro,
  sectionIntro,
}: Props) {
  // accordion toggle
  /////
  const defaultAccordionItems = useMemo(
    () =>
      isEmpty(accordionItems)
        ? []
        : accordionItems?.map((item) => ({
            ...item,
            _id: uuid(),
            _isExpanded: false,
          })),
    [accordionItems],
  );

  const [accItems, setAccItems] = useState(defaultAccordionItems);

  const toggle = useCallback(
    (accItem: AccordionItemType) => () => {
      setAccItems((prev) =>
        prev?.map((prevAccItem) => ({
          ...prevAccItem,
          _isExpanded:
            prevAccItem._id === accItem._id
              ? !prevAccItem._isExpanded // toggle if it is the accordion I've clicked
              : false, // otherwise, close it
        })),
      );
    },
    [setAccItems],
  );

  return (
    <Block>
      <div className="grid grid-rows-[auto_1fr]">
        {sectionIntro?.shouldDisplay && (
          <SectionIntro
            colorway={colorway}
            heading={sectionIntro.heading}
            intro={sectionIntro.intro}
            overline={sectionIntro.overline}
            shouldDisplay={sectionIntro.shouldDisplay}
          />
        )}

        <div className="m-auto flex w-full flex-col lg:max-w-full lg:flex-row lg:items-center lg:space-x-28">
          <div className="align-center flex flex-col lg:w-[30%] lg:self-baseline">
            <div className="mb-6 text-center lg:text-left">
              <RichTextSimpleComponent content={intro} colorway={colorway} />
            </div>
          </div>

          <div className="mt-8 lg:w-[70%] lg:flex-1">
            {accItems?.map((accordion, i) => (
              <FAQAccordionItem
                body={accordion.body}
                colorway={colorway}
                index={i}
                key={i}
                title={accordion.title}
                video={accordion.video}
                toggle={toggle(accordion)}
                isExpanded={!!accordion?._isExpanded}
              />
            ))}

            <div>{!!CTA && <Footer colorway={colorway} CTA={CTA} />}</div>
          </div>
        </div>
      </div>
    </Block>
  );
}

FAQAccordion.displayName = "FAQAccordion";
