import { q, z } from "groqd";

import { imageSelection } from "@/selections/image-selection";
import { sanityInternalsSelection } from "@/selections/sanity-internals-selection";

export const ctaGridSelection = {
  ...sanityInternalsSelection,
  cols: q.array(
    q.object({
      text: z.nullable(q.contentBlocks().optional()),
      image: q.object(imageSelection),
    }),
  ),
};
