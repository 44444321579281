import { memo } from "react";
import { TypeFromSelection, q } from "groqd";
import { Transition } from "@headlessui/react";

import { Colorways } from "../../theme";
import { RichTextSimpleComponent } from "../RichTextSimpleComponent";
import { Minus, Plus } from "./svg";

const selection = { body: q.contentBlocks() };
type RichTextsType = TypeFromSelection<typeof selection>;

type HeaderProps = {
  colorway: Colorways;
  index: string;
  isExpanded: boolean;
  showIterator: boolean;
  title: string;
};
const Header = memo(
  ({ colorway, index, isExpanded, title, showIterator }: HeaderProps) => {
    const textColor = `text-${colorway}`;
    const titleMargin = showIterator ? "m-0" : "my-auto";

    return (
      <div className="border-t-solid flex flex-row justify-between border-t-[1px] border-[#CCCCCC] py-6">
        <div className={`text-head-7 text-onyx ${titleMargin}`}>
          {showIterator && (
            <h1 className={textColor}>
              <strong>{index}</strong>
            </h1>
          )}

          <p>{title}</p>
        </div>

        <span className={`${textColor}`}>
          {isExpanded ? <Minus /> : <Plus />}
        </span>
      </div>
    );
  },
);
Header.displayName = "Header";

type BodyProps = { colorway: Colorways; isExpanded: boolean } & RichTextsType;
const Body = memo(({ body, colorway, isExpanded }: BodyProps) => {
  return (
    <Transition
      show={isExpanded}
      enter="transition-all duration-500 ease-in"
      enterFrom="max-h-0 opacity-0"
      enterTo="max-h-screen opacity-100"
      leave="transition-all duration-200 ease-out"
      leaveFrom="max-h-screen opacity-100"
      leaveTo="max-h-0 opacity-0"
    >
      <div className="flex flex-col items-center pb-8 md:flex-row">
        <div className="text-body-2 pr-12 text-onyx-light xl:pr-16">
          <RichTextSimpleComponent content={body} colorway={colorway} />
        </div>
      </div>
    </Transition>
  );
});

Body.displayName = "Body";

type SingleAccordionItemProps = {
  isExpanded: boolean;
  colorway: Colorways;
  index: number;
  showIterator: boolean;
  title: string;
  toggle: () => void;
} & RichTextsType;
export const SingleAccordionItem = memo(
  ({
    isExpanded,
    body = [],
    colorway,
    index,
    title,
    showIterator = false,
    toggle,
  }: SingleAccordionItemProps) => {
    const number = index < 10 ? `0${index + 1}` : (index + 1).toString();

    return (
      <div role="button" onClick={toggle}>
        <Header
          colorway={colorway}
          isExpanded={isExpanded}
          index={number}
          showIterator={showIterator}
          title={title}
        />

        <div className={`${!isExpanded && "hidden"}`}>
          <Body body={body} colorway={colorway} isExpanded={isExpanded} />
        </div>
      </div>
    );
  },
);

SingleAccordionItem.displayName = "SingleAccordionItem";
