export type ClockProps = {
  className?: string;
  unsize?: boolean;
};

export function Clock(props: ClockProps) {
  return (
    <svg
      className={props.className}
      width={props.unsize ? undefined : "15"}
      height={props.unsize ? undefined : "15"}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <circle cx="7.5" cy="7.5" r="7" stroke="currentColor" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.44955 3.75H7.51291C7.70557 3.75 7.86688 3.89599 7.88605 4.08769L8.22728 7.5L10.6633 8.89203C10.7802 8.9588 10.8523 9.08305 10.8523 9.21762V9.375C10.8523 9.53319 10.724 9.66142 10.5659 9.66142C10.5404 9.66142 10.5151 9.65803 10.4905 9.65133L7.02628 8.70654C6.85275 8.65922 6.73726 8.49534 6.75105 8.316L7.07565 4.09624C7.09068 3.90086 7.2536 3.75 7.44955 3.75Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

export default Clock;
