import { memo } from "react";
import { TypeFromSelection, q } from "groqd";
import { Transition } from "@headlessui/react";
import { isEmpty } from "lodash";

import { Colorways } from "../../theme";
import { RichTextSimpleComponent } from "../RichTextSimpleComponent";
import { VideoThumb, VideoThumbProps } from "../VideoThumb";
import { Minus, Plus } from "./svg";

const selection = { body: q.contentBlocks() };
type RichTextsType = TypeFromSelection<typeof selection>;

type HeaderProps = {
  colorway: Colorways;
  isExpanded: boolean;
  title: string;
};
const Header = memo(({ colorway, isExpanded, title }: HeaderProps) => {
  const textColor = `text-${colorway}`;

  return (
    <div className="border-t-solid flex flex-row justify-between border-t-[1px] border-[#CCCCCC] py-6">
      <div className="text-head-7 my-auto text-onyx">
        <p>{title}</p>
      </div>

      <span className={`${textColor}`}>
        {isExpanded ? <Minus /> : <Plus />}
      </span>
    </div>
  );
});
Header.displayName = "Header";

type BodyProps = {
  colorway: Colorways;
  video: VideoThumbProps | null;
  isExpanded: boolean;
} & RichTextsType;
const Body = memo(({ body, colorway, video, isExpanded }: BodyProps) => {
  const videoDimensions = isEmpty(video)
    ? "md:w-0"
    : "w-full md:w-[35%] h-full md:mr-8";
  const textDimensions = isEmpty(video) ? "md:w-[100%]" : "md:w-[65%]";

  return (
    <Transition
      show={isExpanded}
      enter="transition-all duration-500 ease-in"
      enterFrom="max-h-0 opacity-0"
      enterTo="max-h-screen opacity-100"
      leave="transition-all duration-200 ease-out"
      leaveFrom="max-h-screen opacity-100"
      leaveTo="max-h-0 opacity-0"
    >
      <div className="flex flex-col items-start pb-8 md:flex-row">
        <div className={videoDimensions}>
          {!isEmpty(video) && (
            <VideoThumb
              config={video.config}
              duration={video.duration}
              posterImage={video.posterImage}
              videoURL={video.videoURL}
            />
          )}
        </div>

        <div
          className={`${textDimensions} text-body-2 pr-12 text-onyx-light xl:pr-16`}
        >
          <RichTextSimpleComponent content={body} colorway={colorway} />
        </div>
      </div>
    </Transition>
  );
});

Body.displayName = "Body";

type FAQAccordionItemProps = {
  isExpanded: boolean;
  colorway: Colorways;
  index: number;
  video: VideoThumbProps | null;
  title: string;
  toggle: () => void;
} & RichTextsType;
export const FAQAccordionItem = memo(
  ({
    body = [],
    colorway,
    title,
    video,
    isExpanded,
    toggle,
  }: FAQAccordionItemProps) => {
    return (
      <div>
        <div role="button" onClick={toggle}>
          <Header colorway={colorway} isExpanded={isExpanded} title={title} />
        </div>

        <div className={`${!isExpanded && "hidden"}`}>
          <Body
            body={body}
            colorway={colorway}
            video={video}
            isExpanded={isExpanded}
          />
        </div>
      </div>
    );
  },
);

FAQAccordionItem.displayName = "FAQAccordionItem";
