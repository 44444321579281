import { q, z } from "groqd";
import { sectionIntroSelection } from "../SectionIntro";
import { imageSelection } from "@/selections/image-selection";
import { sanityInternalsSelection } from "@/selections/sanity-internals-selection";
import { ctaButtonSelection } from "../CtaButton";

export const carouselTabSelection = {
  body: q.contentBlocks(),
  ctaButton: z.nullable(q.object(ctaButtonSelection).optional()),
  imageOrVideo: q.object(imageSelection),
  title: q.string(),
  _id: z.nullable(q.string().optional()), // NOTE: for internal use only, not presented on CMS.
  _isExpanded: z.nullable(q.boolean().optional()), // NOTE: for internal use only, not presented on CMS.
};

export const carouselSelection = {
  ...sanityInternalsSelection,
  sectionIntro: z.nullable(q.object(sectionIntroSelection)),
  config: q.object({
    animationInterval: q.number(),
  }),
  carouselTabs: q.array(q.object(carouselTabSelection)),
};
