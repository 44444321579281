import { ButtonBack, ButtonNext, Slide, Slider } from "pure-react-carousel";
import Image from "next/image";
import { Colorways } from "../../../theme";
import { CarouselTabs, CarouselTab } from "../types";
import { getImageURL } from "../../../utils/getImageURL";
import { RichTextSimpleComponent } from "../../RichTextSimpleComponent";
import { CtaButton } from "../../CtaButton";
import { getImageDimensions } from "@sanity/asset-utils";

type CarouselSlide = {
  carouselTab: CarouselTab;
  colorway: Colorways;
};
const CarouselSlide = ({ carouselTab, colorway }: CarouselSlide) => {
  const image = carouselTab?.imageOrVideo;

  const { width, height } = getImageDimensions({
    ...image,
    asset: image.asset || null,
  });

  const widthCorrected = image.is2x ? width / 2 : width;
  const heightCorrected = image.is2x ? height / 2 : height;

  return (
    <div className="grid grid-cols-2 items-start gap-x-10">
      {!!image?.asset && (
        <Image
          width={widthCorrected}
          height={heightCorrected}
          style={{ margin: "0 auto" }}
          sizes="100vw"
          className={`flex max-w-full justify-center self-center`}
          src={getImageURL(image).url()}
          alt="decorative carousel image"
          quality={90}
        />
      )}

      <div className="text-pretty md:pr-4 lg:pr-12  lg:pt-14 xl:pr-16">
        <RichTextSimpleComponent
          content={carouselTab.body}
          colorway={colorway}
        />

        {carouselTab?.ctaButton?.shouldDisplay && (
          <div className="mt-8">
            <CtaButton colorway={colorway} {...carouselTab.ctaButton} />
          </div>
        )}
      </div>
    </div>
  );
};

type SlidesProps = {
  colorway: Colorways;
  carouselTabs: CarouselTabs;
};

export const Slides = ({ carouselTabs, colorway }: SlidesProps) => {
  const arrowColor = `text-${colorway}`;
  const bg = `bg-${colorway}`;

  return (
    <div className="mt-16 grid grid-cols-[min-content,minmax(0,1fr),min-content] items-center gap-x-8">
      <ButtonBack
        className={`text-head-5 rollover-animation flex h-16 w-16 cursor-pointer items-center justify-center rounded-full ${arrowColor} border-[2px] border-solid border-platinum`}
      >
        ←
      </ButtonBack>

      <Slider>
        {carouselTabs.map((carouselTab, i) => (
          <Slide index={i} key={i}>
            <CarouselSlide colorway={colorway} carouselTab={carouselTab} />
          </Slide>
        ))}
      </Slider>

      <ButtonNext
        className={`text-head-5 rollover-animation mx-auto flex h-16 w-16 cursor-pointer items-center justify-center rounded-full text-white ${bg}`}
      >
        →
      </ButtonNext>
    </div>
  );
};
