import { q } from "groqd";

import { sanityInternalsSelection } from "@/selections/sanity-internals-selection";

// https://formidable.com/open-source/groqd/utility-types#typefromselection
export const ctaSelection = {
  ...sanityInternalsSelection,
  _key: q.string(),
  heading: q.string(),
  copy: q.string(),
  url: q.string(),
};

export const { query: ctaQuery, schema: ctaSchema } = q("*", { isArray: true }) // `*[_type == "CTABlock"]`
  .filter(`_type == "CTABlock"`)
  .grab(ctaSelection);
