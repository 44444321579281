import Image from "next/image";
import { getClassName } from "@pairtreefamily/utils";

import { getImageURL } from "../../utils/getImageURL";
import { formatDurationFromSeconds } from "../../utils/formatDurationFromSeconds";
import Card from "../card";
import Clock from "../icons/clock";
import { LinkTo } from "../linkTo";
import { ArticleType } from "./types";
import { getCategoryForCard } from "../insightCard";

export function ArticleCard({
  primaryImage,
  slug,
  category,
  readTime,
  title,
}: ArticleType) {
  const className = getClassName(
    "flex flex-col gap-2",
    "h-full",
    "rounded-2xl group",
  );

  return (
    <LinkTo.Insights slug={slug}>
      <Card className={className}>
        {!!primaryImage?.asset && (
          <div className="relative h-[220px] overflow-hidden rounded-lg">
            <Image
              fill
              sizes=""
              className="object-cover transition-transform duration-200 ease-in-out group-hover:scale-[1.05] "
              src={getImageURL(primaryImage).url()}
              alt="decorative image"
            />
          </div>
        )}

        {category && (
          <p className="text-overline-3 mt-3 text-lightgray">
            {getCategoryForCard(category)}
          </p>
        )}

        <p className="text-head-7 line-clamp-2 text-ellipsis text-onyx">
          {title}
        </p>

        {readTime && (
          <div className="justify-left flex flex-row text-darkgray">
            <Clock className={"mr-2 inline"} />
            <span className="text-body-4 text-darkgray">
              {formatDurationFromSeconds(readTime, true)}
            </span>
          </div>
        )}
      </Card>
    </LinkTo.Insights>
  );
}
