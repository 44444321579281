import { q, z } from "groqd";

import { sanityInternalsSelection } from "@/selections/sanity-internals-selection";
import { sectionIntroSelection } from "../SectionIntro";
import { videoThumbSelection } from "../VideoThumb";

export const ctaSelection = {
  text: z.nullable(q.string().optional()),
  url: z.nullable(q.string().optional()),
};

export const accordionItemSelection = {
  // { "accordionItems": accordionItems[]{} }
  body: q.contentBlocks(), // { "accordionItems": accordionItems[]{body} }
  video: q("video") // { "accordionItems": accordionItems[]{body, video} }
    .deref() // { "accordionItems": accordionItems[]{body, video->} }
    .grab(videoThumbSelection) // { "accordionItems": accordionItems[]{body, "video": video->{duration, videoURL, ...} } }
    .nullable(),
  title: q.string(), // { "accordionItems": accordionItems[]{body, "video": video->{duration, videoURL, ...}, title} }
  _id: z.nullable(q.string().optional()), // NOTE: for internal use only, not presented on CMS.
  _isExpanded: z.nullable(q.boolean().optional()), // NOTE: for internal use only, not presented on CMS.
};

export const FAQAccordionSelection = {
  ...sanityInternalsSelection,
  sectionIntro: z.nullable(q.object(sectionIntroSelection)),
  accordionItems: q("accordionItems") // { accordionItems }
    .filter() // { "accordionItems": accordionItems[] }
    .grab(accordionItemSelection)
    .nullable(),
  intro: q.contentBlocks(),
  CTA: z.nullable(q.object(ctaSelection)),
};
