export const Clock = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="7.97407" cy="8.58247" r="7.36762" stroke="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.92456 4.89868H7.98681C8.17607 4.89868 8.33453 5.04209 8.35337 5.23041L8.68857 8.58249L11.0816 9.94995C11.1964 10.0155 11.2672 10.1376 11.2672 10.2698V10.4244C11.2672 10.5798 11.1413 10.7058 10.9859 10.7058C10.9609 10.7058 10.936 10.7024 10.9118 10.6959L7.50877 9.76774C7.3383 9.72125 7.22485 9.56026 7.2384 9.38409L7.55727 5.23881C7.57203 5.04688 7.73207 4.89868 7.92456 4.89868Z"
      fill="white"
      stroke="white"
      strokeWidth="0.5"
    />
  </svg>
);
