import { q } from "groqd";

import { imageSelection } from "@/selections/image-selection";
import { sanityInternalsSelection } from "@/selections/sanity-internals-selection";

export const reviewerSelection = {
  title: q.string(),
  subtitle: q.string(),
};

export const slidingTestimonialsCarouselSelection = {
  ...sanityInternalsSelection,
  config: q.object({
    animationInterval: q.number(),
  }),
  testimonials: q.array(
    q.object({
      review: q.string(),
      image: q.object(imageSelection),
      reviewer: q.object(reviewerSelection),
    }),
  ),
};

export const {
  query: slidingTestimonialsCarouselQuery,
  schema: slidingTestimonialsCarouselSchema,
} = q("*", {
  isArray: false,
})
  .filter(`_type == "SlidingTestimonialsCarousel"`)
  .grab(slidingTestimonialsCarouselSelection);
