import { memo, useCallback, useMemo, useState } from "react";
import { Transition } from "@headlessui/react";
import Image from "next/image";
import { isEmpty } from "lodash";

import { Colorways } from "../../../theme";
import { RichTextSimpleComponent } from "../../RichTextSimpleComponent";
import { Block } from "../../block";
import { CarouselTab, CarouselTabs } from "../types";
import { getImageURL } from "../../../utils/getImageURL";
import { SectionIntroType, SectionIntro } from "../../SectionIntro";
import { Minus, Plus } from "./svg";
import { CtaButton } from "../../CtaButton";
import { getImageDimensions } from "@sanity/asset-utils";
import { uuid } from "../../../utils/uuid";

type HeaderProps = {
  colorway: Colorways;
  index: string;
  isExpanded: boolean;
  title: string;
};
const Header = memo(({ colorway, index, isExpanded, title }: HeaderProps) => {
  const textColor = `text-${colorway}`;

  return (
    <div className="border-t-solid flex flex-row justify-between border-t border-lightgray py-6">
      <div className={`m-0`}>
        <p className={`text-body-3 text-quicksilver`}>{index}</p>
        <p className="text-head-6 text-onyx">{title}</p>
      </div>

      <span className={`${textColor}`}>
        {isExpanded ? <Minus /> : <Plus />}
      </span>
    </div>
  );
});
Header.displayName = "Header";

type BodyProps = {
  carouselTab: CarouselTab;
  colorway: Colorways;
  isExpanded: boolean;
};

const Body = memo(({ carouselTab, colorway, isExpanded }: BodyProps) => {
  const image = carouselTab?.imageOrVideo;

  const { width, height } = getImageDimensions({
    ...image,
    asset: image.asset || null,
  });

  const widthCorrected = image.is2x ? width / 2 : width;
  const heightCorrected = image.is2x ? height / 2 : height;

  return (
    <Transition
      show={isExpanded}
      enter="transition-all duration-500 ease-in"
      enterFrom="max-h-0 opacity-0"
      enterTo="max-h-screen opacity-100"
      leave="transition-all duration-200 ease-out"
      leaveFrom="max-h-screen opacity-100"
      leaveTo="max-h-0 opacity-0"
    >
      <div className="text-body-2 mb-6 flex flex-col items-center justify-center gap-6 text-onyx-light md:flex-row">
        {!!image.asset && (
          <Image
            width={widthCorrected}
            height={heightCorrected}
            style={{ margin: "0 auto" }}
            sizes="(max-width: 768px) 315px, (max-width: 1200px) w-full, w-full"
            className={`flex max-w-full justify-center self-center`}
            src={getImageURL(image).url()}
            alt="decorative accordion image"
            quality={90}
          />
        )}

        <div className="flex flex-col">
          <RichTextSimpleComponent
            content={carouselTab.body}
            colorway={colorway}
          />

          {carouselTab?.ctaButton?.shouldDisplay && (
            <div className="mt-8">
              <CtaButton colorway={colorway} {...carouselTab.ctaButton} />
            </div>
          )}
        </div>
      </div>
    </Transition>
  );
});

Body.displayName = "Body";

type CarouselAccordionItemProps = {
  carouselTab: CarouselTab;
  colorway: Colorways;
  index: number;
  isExpanded: boolean;
  toggle: () => void;
};
export const CarouselAccordionItem = memo(
  ({
    carouselTab,
    colorway,
    index,
    isExpanded,
    toggle,
  }: CarouselAccordionItemProps) => {
    const number = index < 10 ? `0${index + 1}` : (index + 1).toString();

    return (
      <div role="button" onClick={toggle}>
        <Header
          colorway={colorway}
          isExpanded={isExpanded}
          index={number}
          title={carouselTab.title}
        />

        <div className={`${!isExpanded && "hidden"}`}>
          <Body
            carouselTab={carouselTab}
            colorway={colorway}
            isExpanded={isExpanded}
          />
        </div>
      </div>
    );
  },
);

CarouselAccordionItem.displayName = "CarouselAccordionItem";

type CarouselAccordionProps = {
  carouselTabs: CarouselTabs;
  colorway: Colorways;
  sectionIntro: SectionIntroType | null;
};
export const CarouselAccordion = ({
  carouselTabs,
  colorway,
  sectionIntro,
}: CarouselAccordionProps) => {
  // accordion toggle
  /////
  const defaultCarouselTabs = useMemo(
    () =>
      isEmpty(carouselTabs)
        ? []
        : carouselTabs?.map((item) => ({
            ...item,
            _id: uuid(),
            _isExpanded: false,
          })),
    [carouselTabs],
  );

  const [tabs, setTabs] = useState(defaultCarouselTabs);

  const toggle = useCallback(
    (carouselTab: CarouselTab) => () => {
      setTabs((prevTabs) =>
        prevTabs?.map((prevTab) => ({
          ...prevTab,
          _isExpanded:
            prevTab._id === carouselTab._id
              ? !prevTab._isExpanded // toggle if it is the accordion I've clicked
              : false, // otherwise, close it
        })),
      );
    },
    [setTabs],
  );

  return (
    <Block>
      {sectionIntro?.shouldDisplay && (
        <SectionIntro
          colorway={colorway}
          heading={sectionIntro.heading}
          intro={sectionIntro.intro}
          overline={sectionIntro.overline}
          shouldDisplay={sectionIntro.shouldDisplay}
        />
      )}

      <div className="flex flex-col">
        {tabs?.map((carouselTab, i) => (
          <CarouselAccordionItem
            colorway={colorway}
            carouselTab={carouselTab}
            index={i}
            key={i}
            toggle={toggle(carouselTab)}
            isExpanded={!!carouselTab?._isExpanded}
          />
        ))}
      </div>
    </Block>
  );
};
