import { q, z } from "groqd";

export const quickLinkSelection = {
  text: q.string(),
  url: q.string(),
};

export const quickLinksSelection = {
  links: z.nullable(q.array(q.object(quickLinkSelection)).optional()),
  config: q.object({
    showQuickLinks: q.boolean(),
  }),
};
