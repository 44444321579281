import { q, z, sanityImage } from "groqd";
import type { TypeFromSelection } from "groqd";
import Image from "next/image";
import { isEmpty } from "lodash";

import { getImageURL } from "../../utils/getImageURL";
import { RichTextSimpleComponent } from "../RichTextSimpleComponent";
import { quickLinksSelection } from "../QuickLinks";
import QuickLinks from "../QuickLinks/QuickLinks";
import { Colorways } from "src/theme";
import { Block } from "../block";
import { VideoThumb, videoThumbSelection } from "../VideoThumb";

export const HeroTwoColSelection = {
  _type: q.string(),
  overline: z.nullable(q.string().optional()),
  heading: z.nullable(q.string().optional()),
  body: q.contentBlocks(),
  image: sanityImage("image"),
  quickLinks: q.object(quickLinksSelection),
  video: q("video").deref().grab(videoThumbSelection).nullable(),
};

type Props = TypeFromSelection<typeof HeroTwoColSelection> & {
  colorway: Colorways;
};
export default function HeroTwoColumn(props: Props): JSX.Element {
  const { overline, heading, body, image, colorway, quickLinks, video } = props;

  return (
    <Block fullWidth flushTop flushBottom>
      <div className="flex flex-col lg:flex-row">
        <div
          className={`relative flex flex-col bg-darkblue bg-${colorway}-gradient w-full text-white max-lg:order-2`}
        >
          <div className="absolute bottom-0 left-0">
            <Image
              src="/hero-vector-overlay.png"
              width={0}
              height={0}
              style={{ width: "100%" }}
              sizes="w-full"
              className={`position-center flex w-full object-cover`}
              alt="hero"
              quality={90}
            />
          </div>

          <div className="flex min-h-[250px] grow flex-col items-center justify-center px-5 py-10 md:min-h-[320px] xl:px-[16%]">
            {!!overline && (
              <span className="text-overline-2 text-white">{overline}</span>
            )}
            {!!heading && (
              <h2 className="text-deco-head-3 lg:text-deco-head-1 text-pretty text-center text-white">
                {heading}
              </h2>
            )}
            <div className="text-center">
              {!!body && (
                <RichTextSimpleComponent content={body} colorway={colorway} />
              )}
            </div>
          </div>
        </div>

        <div className="w-full">
          <div className="relative flex min-h-[320px] overflow-hidden max-lg:order-1 lg:min-h-[490px]">
            {!!image && isEmpty(video) && (
              <Image
                fill
                className={`flex w-full object-cover`}
                src={getImageURL(image).url()}
                alt={props.heading ?? "PairTree"}
                quality={90}
              />
            )}

            {!isEmpty(video) && (
              <VideoThumb
                config={video?.config}
                duration={video?.duration}
                posterImage={video?.posterImage}
                videoURL={video?.videoURL}
                imgClassNames="object-cover"
                classNames="w-full flex grow"
                fill={true}
              />
            )}
          </div>
        </div>
      </div>

      {!isEmpty(quickLinks) && quickLinks?.config?.showQuickLinks && (
        <QuickLinks {...quickLinks} colorway={colorway} />
      )}
    </Block>
  );
}
