import { q, z } from "groqd";

import { sanityInternalsSelection } from "@/selections/sanity-internals-selection";
import { imageSelection } from "@/selections/image-selection";
import { videoThumbSelection } from "../VideoThumb";
import { ctaButtonSelection } from "../CtaButton";

const categorySelection: [string, z.ZodType] = ["_type", q.string()];
export const articleSelection = {
  title: z.nullable(q.string().optional()),
  primaryImage: z.nullable(q.object(imageSelection)),
  readTime: z.nullable(q.number().optional()),
  slug: q.slug("slug"),
  category: categorySelection, // Needed to declare to fix Typescript compiler unintelligibility
  video: q("video").deref().grab(videoThumbSelection).nullable(),
};

export const featuredArticlesSelection = {
  ...sanityInternalsSelection,
  overline: z.nullable(q.string().optional()),
  heading: z.nullable(q.string().optional()),
  body: z.nullable(q.string().optional()),
  ctaButton: z.nullable(q.object(ctaButtonSelection)),
  featuredArticles: q("featuredArticles")
    .filter()
    .deref()
    .grab(articleSelection),
};
