import { memo } from "react";

import { LinkTo } from "../linkTo";
import { Colorways } from "../../theme";
import { CtaProps } from "./types";

type Props = {
  colorway: Colorways;
  CTA: CtaProps;
};
export const Footer = memo(({ colorway, CTA }: Props) => {
  return (
    <div className="border-t border-lightgray pb-8 pt-8 text-center">
      <div className="text-deco-head-5 text-onyx">
        <strong>
          <em>{CTA?.text1}</em>
        </strong>
      </div>

      {!!CTA?.url && (
        <LinkTo.External
          url={CTA?.url}
          className={`text-head-6 text-${colorway}`}
        >
          <strong>{CTA?.text2}</strong>
        </LinkTo.External>
      )}
    </div>
  );
});
Footer.displayName = "Footer";
