import { getImageDimensions } from "@sanity/asset-utils";

export const getWidthCorrected = (image: any) => {
  const { width } = getImageDimensions({
    ...image,
    asset: image.asset || null,
  });

  return image.is2x ? width / 2 : width;
};

export const getHeightCorrected = (image: any) => {
  const { height } = getImageDimensions({
    ...image,
    asset: image.asset || null,
  });

  return image.is2x ? height / 2 : height;
};
