import { memo, useEffect, useMemo } from "react";
import { isEmpty } from "lodash";
import { useIntersectionObserver } from "@uidotdev/usehooks";

import { VideoGridType, VideoType } from "./types";
import { Block } from "../block";
import { VideoThumb } from "../VideoThumb";

export type VideoProps = VideoType;
export const Video = memo(({ description, title, video }: VideoProps) => {
  const [ref, entry] = useIntersectionObserver({
    threshold: 0.25, // What percentage of the target’s visibility the observer’s callback should be executed.
    root: null,
    rootMargin: "0px",
  });

  const scaling = useMemo(
    () => (entry?.isIntersecting ? "max-sm:scale-100" : "max-sm:scale-90"),
    [entry],
  );

  return (
    <div
      ref={ref}
      className={`flex max-w-[283px] flex-col text-center transition duration-1000 ease-in-out lg:max-w-none lg:text-start ${scaling}`}
    >
      {!isEmpty(video) && (
        <VideoThumb
          config={video.config}
          duration={video.duration}
          imgClassNames="aspect-square md:aspect-video object-cover"
          posterImage={video.posterImage}
          videoURL={video.videoURL}
        />
      )}

      <div className="mt-5">
        <p className="text-head-6 text-onyx">{title}</p>

        <p className="text-body-3 mt-3 text-gray-tint">{description}</p>
      </div>
    </div>
  );
});

Video.displayName = "Video";

type VideoGridProps = VideoGridType;
export default function VideoGrid({ videos }: VideoGridProps) {
  return (
    <div className="mb-8 flex flex-col items-center lg:px-16 xl:my-8 xl:mb-24">
      <div className="mx-auto mt-6 max-w-full lg:container ">
        <div className="grid grid-flow-col gap-8 max-lg:auto-cols-max max-lg:space-x-8 max-lg:overflow-x-scroll max-lg:p-8 lg:auto-cols-[1fr]">
          {videos.map((v, i) => (
            <Video key={i} {...v} />
          ))}
        </div>
      </div>
    </div>
  );
}

VideoGrid.displayName = "VideoGrid";
