import { ReactNode, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";

export type ClientPortalProps = {
  children: ReactNode;
  selector: string;
};

// create a portal using the passed selector as the root element.
// This portal will only be rendered client-side; see this example
// project if server-side portals are needed
// https://github.com/vercel/next.js/tree/canary/examples/with-portals-ssr
export function ClientPortal(props: ClientPortalProps) {
  const container = useRef<Element>();
  // the ref won't trigger a re-render on its own; this state exists solely to
  // prompt a rerender if the container changes
  const [containerEstablished, setContainerEstablished] = useState(false);

  useEffect(() => {
    const element = document.querySelector(props.selector);
    if (element) {
      container.current = element;
    }
    setContainerEstablished(true);
  }, [props.selector]);

  if (!container.current || !containerEstablished) {
    return null;
  }

  return ReactDOM.createPortal(props.children, container.current);
}

export default ClientPortal;
