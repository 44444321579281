import { useCallback, useMemo, useState } from "react";
import Image from "next/image";
import validator from "validator";

import { CTABannerType } from "./types";
import { getImageURL } from "../../utils/getImageURL";
import { Colorways } from "../../theme";
import { AdoptionTypes } from "./enums";
import { getImageDimensions } from "@sanity/asset-utils";

type Props = { colorway: Colorways } & CTABannerType;

export default function CTABanner({ body, colorway, heading, image }: Props) {
  const [firstName, setFirstName] = useState<string>("");
  const [firstNameError, setFirstNameError] = useState<string>("");

  const [lastName, setLastName] = useState<string>("");
  const [lastNameError, setLastNameError] = useState<string>("");

  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");

  const [selectedTypeError, setSelectedTypeError] = useState<string>("");
  const [selectedType, setSelectedType] = useState<string>("");

  const change = (setValue: any) => (e: any) => setValue(e.target.value);
  const changeFirstName = change(setFirstName);
  const changeLastName = change(setLastName);
  const changeEmail = change(setEmail);
  const changeSelectedType = change(setSelectedType);

  const validateFirstName = () => {
    if (!firstName) return setFirstNameError("Can't be empty");
    setFirstNameError("");
  };

  const validateLastName = () => {
    if (!lastName) return setLastNameError("Can't be empty");
    setLastNameError("");
  };

  const validateEmail = () => {
    if (!email) return setEmailError("Can't be empty");
    if (!validator.isEmail(email)) return setEmailError("Not a valid email");
    setEmailError("");
  };

  const validateSelectedType = () => {
    if (!selectedType) return setSelectedTypeError("Can't be empty");
    setSelectedTypeError("");
  };

  const resetForm = () => {
    setFirstName("");
    setFirstNameError("");
    setLastName("");
    setLastNameError("");
    setEmail("");
    setEmailError("");
    setSelectedType("");
    setSelectedTypeError("");
  };

  const isButtonDisabled = useMemo(() => {
    const hasValues = !!firstName && !!lastName && !!email && !!selectedType;
    const hasErrors =
      !!firstNameError ||
      !!lastNameError ||
      !!emailError ||
      !!selectedTypeError;

    return hasErrors || !hasValues;
  }, [
    firstName,
    firstNameError,
    lastName,
    lastNameError,
    email,
    emailError,
    selectedType,
    selectedTypeError,
  ]);

  const submit = useCallback(async () => {
    const hubspotPortalId = "14522431";
    const hubspotFormId = "3b8dc11a-8b1c-47ec-a572-0f5064442d62";
    const response = await fetch(
      `https://api.hsforms.com/submissions/v3/integration/submit/${hubspotPortalId}/${hubspotFormId}`,
      {
        body: JSON.stringify({
          fields: [
            {
              name: "firstname",
              value: firstName,
            },
            {
              name: "lastname",
              value: lastName,
            },
            {
              name: "email",
              value: email,
            },
            {
              name: "account_type",
              value: selectedType,
            },
          ],
        }),
        headers: { "Content-Type": "application/json" },
        method: "POST",
      },
    );

    if (response.ok) {
      window.alert("Submission successful, we will reach you out soon!");
      resetForm();
    } else window.alert("Submission failed, please try again soon!");
  }, [firstName, lastName, email, resetForm, selectedType]);

  const { width, height } = getImageDimensions({
    ...image,
    asset: image.asset,
  });

  const widthCorrected = image?.is2x ? width / 2 : width;
  const heightCorrected = image?.is2x ? height / 2 : height;

  const fieldClassNames = `text-body-3 border-b-[1px] border-solid !bg-[transparent] placeholder:italic placeholder:text-white text-white focus:outline-none`;

  return (
    <div
      className={`grid w-full auto-rows-auto justify-center px-4 pb-8 lg:grid-cols-2 lg:px-12 bg-${colorway}-gradient gradient-reverse`}
    >
      {!!image?.asset && (
        <Image
          width={widthCorrected}
          height={heightCorrected}
          style={{ margin: "0 auto" }}
          sizes="33vw"
          className="object-cover"
          src={getImageURL(image).url()}
          alt="multi column section image"
        />
      )}

      <div className="flex flex-col gap-3 py-4 lg:pl-8">
        <h3 className="text-deco-head-5 text-center text-white lg:text-left">
          {heading}
        </h3>

        <p className="text-body-2 text-center text-white lg:text-left">
          {body}
        </p>

        <form
          className="flex flex-col gap-8 lg:gap-4"
          onSubmit={(e) => e.preventDefault()}
        >
          <div className="grid grid-rows-2 gap-4 lg:grid-cols-2 lg:grid-rows-none">
            <fieldset className="flex flex-col">
              {firstName !== "" && (
                <label
                  className="text-body-5 mb-1 text-white  "
                  htmlFor="firstName"
                >
                  First name
                </label>
              )}
              <input
                className={`${fieldClassNames} border-${colorway} border-reverse`}
                id="firstName"
                type="text"
                placeholder="First name"
                onBlur={validateFirstName}
                onChange={changeFirstName}
                value={firstName}
              />
              <p className="text-body-5 mt-1 text-white">{firstNameError}</p>
            </fieldset>

            <fieldset className="group flex flex-col">
              {lastName !== "" && (
                <label
                  className="text-body-5 mb-1 text-white"
                  htmlFor="lastName"
                >
                  Last name
                </label>
              )}
              <input
                className={`${fieldClassNames} border-${colorway} border-reverse`}
                id="lastName"
                type="text"
                placeholder="Last name"
                onChange={changeLastName}
                onBlur={validateLastName}
                value={lastName}
              />
              <p className="text-body-5 mt-1 text-white">{lastNameError}</p>
            </fieldset>
          </div>

          <div className="grid grid-rows-2 gap-6 lg:grid-cols-2 lg:grid-rows-none">
            <fieldset className="flex flex-col">
              {email !== "" && (
                <label className="text-body-5 mb-1 text-white" htmlFor="email">
                  Email
                </label>
              )}
              <input
                className={`${fieldClassNames} border-${colorway} border-reverse`}
                id="email"
                onChange={changeEmail}
                onBlur={validateEmail}
                placeholder="Email"
                type="text"
                value={email}
              />

              <p className="text-body-5 mt-1 text-white">{emailError}</p>
            </fieldset>
            <fieldset className="flex flex-col">
              {selectedType !== "" && (
                <label
                  className="text-body-5 mb-1 text-white"
                  htmlFor="selectedType"
                >
                  I am...
                </label>
              )}
              <select
                className={`${fieldClassNames} focus:outline-none lg:mb-1 border-${colorway} border-reverse`}
                id="selectedType"
                value={selectedType}
                onChange={changeSelectedType}
                onBlur={validateSelectedType}
              >
                <option value="" disabled>
                  I am...
                </option>
                <option value={AdoptionTypes.ADOPTION_PROFESSIONAL}>
                  {AdoptionTypes.ADOPTION_PROFESSIONAL}
                </option>
                <option value={AdoptionTypes.ADOPTIVE_PARENT}>
                  {AdoptionTypes.ADOPTIVE_PARENT}
                </option>
                <option value={AdoptionTypes.EXPECTANT_MOM}>
                  {AdoptionTypes.EXPECTANT_MOM}
                </option>
              </select>
            </fieldset>
          </div>

          <button
            className={`text-body-3-medium inline-block w-full rounded-full border-2 px-8 py-2 text-center font-semibold disabled:opacity-50 lg:w-fit border-${colorway} border-reverse bg-${colorway} bg-reverse text-white`}
            disabled={isButtonDisabled}
            onClick={submit}
          >
            Subscribe
          </button>
        </form>
      </div>
    </div>
  );
}
