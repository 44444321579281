import { q } from "groqd";

import { sanityInternalsSelection } from "@/selections/sanity-internals-selection";
import { ctaButtonSelection } from "../CtaButton";
import { imageSelection } from "@/selections/image-selection";

export const meetFamiliesSelection = {
  ...sanityInternalsSelection,
  families: q("families")
    .filter()
    .grab({
      image: q.object(imageSelection),
      slug: q.slug("slug"),
    })
    .nullable(),
  heading: q.string(),
  intro: q.string(),
  ctaButton: q.object(ctaButtonSelection),
};
