import { q, z } from "groqd";

export const imageSelection = {
  _type: q.string(),
  asset: q
    .object({
      _ref: q.string(),
      _type: q.string(),
    })
    .or(z.any()),
  isDecorative: q.boolean(),
  alt: q.string().optional(),
  is2x: z.nullable(q.boolean()).optional(),
};
