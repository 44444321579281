import { q, z } from "groqd";
import { sanityInternalsSelection } from "@/selections/sanity-internals-selection";
import { quickLinksSelection } from "../QuickLinks";
import { LayoutVariant } from "./enums";

export const heroWithFormSelection = {
  ...sanityInternalsSelection,
  intro: q.contentBlocks(),
  formHeading: q.string().optional(),
  formId: q.string(),
  heroLinkAnchor: z.nullable(q.string().optional()),
  quickLinks: q.object(quickLinksSelection),
  config: q.object({
    layoutVariant: z.nativeEnum(LayoutVariant),
  }),
};
