export function formatDurationFromSeconds(
  seconds: number,
  shortVersion?: boolean,
) {
  // create a new date from seconds param...
  const date = new Date(seconds * 1000);
  if (seconds >= 3600) {
    // ...if it's short version return only the hours, else grab the HH:MM portion
    return (
      (shortVersion ? date.getHours() : date.toISOString().substring(11, 16)) +
      "h"
    );
  } else {
    // ...if it's short version return only the minutes, else grab the MM:SS portion
    return (
      (shortVersion
        ? date.getMinutes()
        : date.toISOString().substring(14, 19)) + "m"
    );
  }
}
