import Ticker from "framer-motion-ticker";
import Image from "next/image";

import { LogosBannerProps } from "./types";
import { getImageURL } from "../../utils/getImageURL";

export default function LogosBanner({ config, logos }: LogosBannerProps) {
  return (
    <div className="flex flex-row items-center gap-x-10 bg-backgroundgray py-12 ">
      <Ticker duration={config.duration}>
        {logos.map((logo, index) => (
          <div className="mx-8 w-[200px]" key={index}>
            {logo?.asset && (
              <Image
                width={0}
                height={0}
                sizes="25vw"
                className={`mx-auto flex h-[60px] w-auto items-center`}
                src={getImageURL(logo).url()}
                alt="decorative thumbnail image"
              />
            )}
          </div>
        ))}
      </Ticker>
    </div>
  );
}
