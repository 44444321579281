import { isEmpty } from "lodash";

import { Colorways } from "../../theme";
import { QuickLinksType, QuickLinkType } from "./types";

type QuickLinkProps = QuickLinkType & { classNames?: string };
const QuickLink = ({ classNames, text, url }: QuickLinkProps) => (
  <a
    className={`my-3 cursor-pointer px-3 text-[14px] font-semibold leading-none text-onyx lg:my-0 ${classNames}`}
    href={url}
  >
    {text}
  </a>
);

type QuickLinksProps = QuickLinksType & { colorway: Colorways };
export default function QuickLinks({
  colorway,
  config,
  links,
}: QuickLinksProps) {
  if (isEmpty(links)) return null;
  if (!config?.showQuickLinks) return null;

  return (
    <div
      className={`flex flex-col items-center justify-center bg-cultured py-4 lg:h-[60px] lg:flex-row ${colorway}-colorway`}
    >
      <span className={`text-overline-2 text-${colorway}`}>Quicklinks:</span>

      {links?.map((l, i, a) => {
        const isLastLink = i + 1 === a.length;
        const border = !isLastLink
          ? "lg:border-r lg:border-solid lg:border-quicksilver"
          : "";

        return <QuickLink {...l} key={i} classNames={border} />;
      })}
    </div>
  );
}
