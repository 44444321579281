import { useState, useEffect, useMemo, useCallback, useContext } from "react";
import { CarouselContext } from "pure-react-carousel";

import { Colorways } from "../../theme";

const TAB_ANIMATION_TRANSITION_PROP = "width";
const TAB_ANIMATION_START_PROPS = "w-0";
const TAB_ANIMATION_END_PROPS = "w-full";
const Tab = ({
  animationInterval,
  colorway,
  index,
}: {
  animationInterval: number;
  colorway: Colorways;
  index: number;
}) => {
  const carouselContext = useContext(CarouselContext);

  // states
  /////
  const [currentSlide, setCurrentSlide] = useState(
    carouselContext.state.currentSlide,
  );
  const [animation, setAnimation] = useState(TAB_ANIMATION_START_PROPS);

  // memos
  /////
  const isActive = useMemo(() => currentSlide === index, [currentSlide, index]);

  // memos:css
  /////
  const border = useMemo(
    () => (!isActive ? "border-b-solid border-b-[4px] border-quicksilver" : ""),
    [isActive],
  );
  const animatedBorder = useMemo(
    () =>
      `h-[4px] ${isActive ? `border-b-[4px] block border-${colorway}` : ""}`,
    [colorway, isActive],
  );
  // Tailwind doesn't allow to generate classes dynamically.
  // Meaning that `duration-[${animationInterval}]` (being `duration-[]` a shorthand for an arbitrary value) wouldn't be understood by Tailwind.
  // Although `border-${colorway}` is understood because it is _already defined_ on a css file.
  // Thus, to enable user defined animation interval, the best option is to rely on css styles.
  const transitionStyles = useMemo(
    () => ({
      transitionTimingFunction: "linear",
      transitionProperty: isActive ? TAB_ANIMATION_TRANSITION_PROP : "none",
      transitionDuration: isActive ? `${animationInterval}ms` : "0",
    }),
    [animationInterval, isActive],
  );
  const styling = useMemo(
    () => (isActive ? `w-[104px] lg:w-[56px]` : "w-[65px] lg:w-[35px]"),
    [colorway, isActive],
  );

  // callbacks
  /////
  const goToSlide = useCallback(
    () => carouselContext.setStoreState({ currentSlide: index }),
    [carouselContext, index],
  );

  // effects
  /////
  // Tells to component which is the current slide the carousel is displaying.
  useEffect(() => {
    function onChange() {
      setCurrentSlide(carouselContext.state.currentSlide);
    }

    carouselContext.subscribe(onChange);
    return () => carouselContext.unsubscribe(onChange);
  }, [carouselContext]);

  // Triggers the progressive border animation if the tab is active.
  // Restart the animation initial definition settings upon tab change.
  useEffect(() => {
    if (isActive) setAnimation(TAB_ANIMATION_END_PROPS);
    return () => setAnimation(TAB_ANIMATION_START_PROPS);
  }, [isActive]);

  return (
    <div className="cursor-pointer">
      <div className={`${styling} ${border}`} onClick={goToSlide} />

      <div
        className={`${animatedBorder} ${animation}`}
        style={transitionStyles}
      />
    </div>
  );
};

export const Tabs = ({
  animationInterval,
  colorway,
  totalSlides,
}: {
  animationInterval: number;
  colorway: Colorways;
  totalSlides: number;
}) => {
  const slides = [...Array(totalSlides).keys()]; // `totalSlides == 2` -> `[ 0, 1 ]`

  return (
    <div className="mx-auto mt-12 flex flex-row gap-2 lg:mx-0">
      {slides.map((s, i) => (
        <Tab
          animationInterval={animationInterval}
          colorway={colorway}
          index={s}
          key={s}
        />
      ))}
    </div>
  );
};
