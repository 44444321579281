import { Insight } from "../types/insight";
import { getClassName } from "@pairtreefamily/utils";
import { getImageURL } from "../utils/getImageURL";
import { formatDurationFromSeconds } from "../utils/formatDurationFromSeconds";
import Card from "./card";
import Clock from "./icons/clock";
import { LinkTo } from "./linkTo";
import Image from "next/image";

export type InsightCardProps = Insight;

export function getCategoryForCard(category: string) {
  // this is a temporary display name fix, and ideally will be replaced when more categories are added
  if (category === "insight") {
    return "Article";
  }

  return category;
}

export function InsightCard(props: InsightCardProps) {
  const article = props;
  const image = article.primaryImage;

  const imageClass = getClassName(
    "h-[190px] lg:h-[220px] object-cover group-hover:scale-[1.05] transition-transform duration-200 ease-in-out  ",
  );
  const className = getClassName(
    "flex flex-col gap-2 group overflow-hidden",
    "h-full",
  );

  return (
    <LinkTo.Insights slug={article.slug?.current}>
      <Card className={className}>
        {image && (
          <div className="overflow-hidden rounded-xl">
            <Image
              width={500}
              height={300}
              className={imageClass}
              src={getImageURL(image).url()}
              alt={image.alt ?? ""}
            />
          </div>
        )}
        {article.category && (
          <p className="text-overline-3 text-lightgray">
            {getCategoryForCard(article.category)}
          </p>
        )}
        <p className="text-body-2 line-clamp-2 text-ellipsis font-bold text-onyx">
          {article.title}
        </p>
        {article.readTime && (
          <div className="justify-left flex flex-row text-darkgray">
            <Clock className={"mr-2 inline"} />
            <span className="text-body-4 text-darkgray">
              {formatDurationFromSeconds(article.readTime, true)}
            </span>
          </div>
        )}
      </Card>
    </LinkTo.Insights>
  );
}

export default InsightCard;
