import Image from "next/image";

import { MeetFamiliesType } from "./types";
import { getImageURL } from "@/utils/getImageURL";

type Props = {
  families: MeetFamiliesType["families"] | undefined;
  wrapperClassNames?: string;
};

export const FamilyMosaic = ({ families, wrapperClassNames }: Props) => {
  return (
    <div className={`family-mosaic ${wrapperClassNames || ""}`.trim()}>
      {families?.map((family, i: number) => {
        const imgClassNames = `w-auto grid-area-${i} object-cover rounded-[20px]`;

        return (
          <div className={imgClassNames} key={i}>
            <a
              className="relative block h-full w-full"
              href={`/families/${family.slug}`}
            >
              {!!family?.image?.asset && (
                <Image
                  fill
                  className="cursor-pointer rounded-[20px] object-cover"
                  src={getImageURL(family?.image).url()}
                  alt="decorative image"
                  quality={90}
                />
              )}
            </a>
          </div>
        );
      })}
    </div>
  );
};
