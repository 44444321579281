import { q, z } from "groqd";

import { sanityInternalsSelection } from "@/selections/sanity-internals-selection";
import { videoThumbSelection } from "../VideoThumb";

export const highlightSelection = {
  heading: z.nullable(z.string()).optional(), // { "highlights": highlights[]{heading} }
  intro: z.nullable(q.contentBlocks()).optional(), // { "highlights": highlights[]{heading, intro} }
  video: q("video") // { "highlights": highlights[]{heading, intro, video} }
    .deref() // { "highlights": highlights[]{heading, intro, video->} }
    .grab(videoThumbSelection) // { "highlights": highlights[]{heading, intro, video->{duration, videoURL, ...}} }
    .nullable(),
};

export const videoHighlightSelection = {
  ...sanityInternalsSelection,
  overline: z.nullable(z.string()).optional(),
  config: q.object({
    animationInterval: q.number(),
  }),
  highlights: q("highlights") // { highlights }
    .filter() // { "highlights": highlights[] }
    .grab(highlightSelection), // { "highlights": highlights[]{} }
};

export const { query: VideoHighlightQuery, schema: VideoHighlightSchema } = q(
  "*",
  {
    isArray: false,
  },
)
  .filter(`_type == "VideoHighlight"`) // *[_type == "VideoHighlight"]
  .deref()
  .grab(videoHighlightSelection);
