import { ReactNode } from "react";
import { getClassName } from "@pairtreefamily/utils";
import Mask from "./mask";

export type LightboxProps = {
  className?: string;
  hidden?: boolean;
  children: ReactNode;
  closeLightbox?: () => void;
};

export function Lightbox(props: LightboxProps) {
  const containerClass = getClassName(
    "absolute",
    "flex flex-col justify-center items-center",
    "inset-0 inset-y-[5%] inset-x-[10%]",
    props.className,
  );

  return (
    <Mask onClick={props.closeLightbox} hidden={props.hidden}>
      <div className={containerClass}>{props.children}</div>
    </Mask>
  );
}

export default Lightbox;
