import { ReactNode } from "react";
import { getClassName } from "@pairtreefamily/utils";

export type ListProps = {
  children?: ReactNode;
  className?: string;
};

export function ListItem(props: ListProps) {
  return <li className={getClassName(props.className)}>{props.children}</li>;
}

export type OrderedListProps = {
  style?: "decimal" | "alpha";
  children?: ReactNode;
};

export function OrderedList({ style = "decimal", ...props }: OrderedListProps) {
  const className = getClassName(
    style === "decimal" && "list-decimal",
    style === "alpha" && "list-[lower-alpha]",
    "text-body-3",
    "ml-6",
  );

  return <ol className={className}>{props.children}</ol>;
}

export type UnorderedListProps = {
  style?: "disc" | "check";
  children?: ReactNode;
};

export function UnorderedList({
  style = "disc",
  ...props
}: UnorderedListProps) {
  const className = getClassName(
    style === "disc" && "list-disc",
    style === "check" && "checklist",
    "text-body-3",
    "ml-6",
  );
  return <ul className={className}>{props.children}</ul>;
}
