import { isEmpty } from "lodash";

import { MeetFamiliesType } from "./types";
import { Block } from "../block";
import { Colorways } from "../../theme";
import { CtaButton, CtaButtonCallout } from "../CtaButton";
import { FamilyMosaic } from "./FamilyMosaic";

type Props = MeetFamiliesType & { colorway: Colorways };
export default function MeetFamilies({
  colorway = Colorways.Default,
  ctaButton,
  families,
  heading,
  intro,
}: Props) {
  const familiesMosaic1 = families?.slice(0, 4);
  const familiesMosaic2 = families?.slice(4, 8);

  return (
    <Block>
      <div className="m-auto flex w-full flex-col lg:max-w-full lg:flex-row lg:items-center lg:space-x-12">
        <div className="align-center order-2 flex flex-col lg:order-1 lg:w-[30%] lg:self-baseline">
          <div className={`mb-6 text-center lg:text-left ${colorway}-colorway`}>
            <h1 className="text-deco-head-3 md:text-deco-head-1">
              <strong>
                <em>{heading}</em>
              </strong>
            </h1>

            <p className="text-body-1 my-9 text-gray">{intro}</p>

            {!isEmpty(ctaButton) && ctaButton.shouldDisplay && (
              <div className="md:flex md:flex-col md:items-center lg:items-start">
                <CtaButton colorway={colorway} {...ctaButton} />

                {ctaButton.callout?.shouldDisplay && (
                  <CtaButtonCallout
                    colorway={colorway}
                    callout={ctaButton.callout}
                    wrapperClassNames="max-sm:ml-[0px]"
                  />
                )}
              </div>
            )}
          </div>
        </div>

        <div className="order-1 mb-8 grid grid-cols-[auto] justify-center gap-4 overflow-x-hidden md:grid-cols-[auto,auto,1fr] lg:order-2 lg:mb-0 lg:w-[70%] lg:flex-1 lg:overflow-x-visible">
          <FamilyMosaic families={familiesMosaic1} />
          <FamilyMosaic
            families={familiesMosaic2}
            wrapperClassNames="hidden md:grid"
          />
        </div>
      </div>
    </Block>
  );
}

MeetFamilies.displayName = "MeetFamilies";
