import { q, z } from "groqd";

import { imageSelection } from "@/selections/image-selection";
import { sanityInternalsSelection } from "@/selections/sanity-internals-selection";

export const logosBannerSelection = {
  ...sanityInternalsSelection,
  config: q.object({
    duration: q.number(),
  }),
  logos: q.array(q.object(imageSelection)),
};
