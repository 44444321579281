export const Play = () => (
  <svg viewBox="0 0 92 92" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M46 0.5C20.9 0.5 0.5 20.9 0.5 46C0.5 71.1 20.9 91.5 46 91.5C71.1 91.5 91.5 71.1 91.5 46C91.5 20.9 71.1 0.5 46 0.5ZM46 86.5C23.7 86.5 5.5 68.3 5.5 46C5.5 23.7 23.7 5.5 46 5.5C68.3 5.5 86.5 23.7 86.5 46C86.5 68.3 68.3 86.5 46 86.5Z"
      fill="white"
    />
    <path
      d="M63.7996 44.3L37.5996 29.2C36.9997 28.7999 36.1995 28.7999 35.5996 29.2C34.9997 29.6 34.5996 30.2 34.5996 30.8998V61.0998C34.5996 61.7997 34.9997 62.4999 35.5996 62.7996C35.8997 62.9995 36.2995 63.0998 36.5996 63.0998C36.8997 63.0998 37.2995 62.9998 37.5996 62.7996L63.7996 47.6996C64.3995 47.2996 64.7996 46.6996 64.7996 45.9998C64.7999 45.3002 64.3999 44.6 63.7999 44.3002L63.7996 44.3Z"
      fill="white"
    />
  </svg>
);
