import { q, z } from "groqd";

import { imageSelection } from "@/selections/image-selection";
import { sanityInternalsSelection } from "@/selections/sanity-internals-selection";

export const videoThumbSelection = {
  ...sanityInternalsSelection,
  config: q.object({
    showDuration: q.boolean(),
    showPlayButton: q.boolean(),
  }),
  duration: z.nullable(q.string().optional()),
  posterImage: q.object(imageSelection),
  videoURL: q.string(),
};
