import { ReactNode, useEffect } from "react";
import { BodyScroll } from "../utils/bodyScroll";
import { getClassName } from "@pairtreefamily/utils";
import ClientPortal from "./clientPortal";
import { maskPortalRootClass } from "./layout";

export type MaskProps = {
  children?: ReactNode;
  hidden?: boolean;
  onClick?: () => void;
};

export function Mask(props: MaskProps) {
  const containerClass = getClassName(
    "fixed",
    "left-0 w-screen",
    "top-0 h-screen",
    "z-[100]",
    props.hidden && "hidden",
  );

  const maskClass = getClassName(
    "fixed",
    "left-0 w-full",
    "top-0 h-full",
    "bg-black",
    "opacity-70",
  );

  useEffect(() => {
    if (props.hidden !== undefined) {
      if (props.hidden) {
        BodyScroll.enable();
      } else {
        BodyScroll.disable();
      }
    } else {
      BodyScroll.disable();
    }

    return BodyScroll.enable;
  }, [props.hidden]);

  return (
    <ClientPortal selector={maskPortalRootClass}>
      <div className={containerClass}>
        <div onClick={props.onClick} className={maskClass} />
        {props.children}
      </div>
    </ClientPortal>
  );
}

export default Mask;
