import { useCallback, useMemo, useState } from "react";
import { TypeFromSelection } from "groqd";
import Image from "next/image";
import { isEmpty } from "lodash";
import { getImageDimensions } from "@sanity/asset-utils";

import { Block } from "../block";
import { Colorways } from "../../theme";
import { getImageURL } from "../../utils/getImageURL";
import { singleImageAccordionSelection } from "./query";
import { RichTextSimpleComponent } from "../RichTextSimpleComponent";
import { SingleAccordionItem } from "./SingleAccordionItem";
import { Footer } from "./Footer";
import { SectionIntro } from "../SectionIntro";
import { AccordionItemType } from "./types";
import { uuid } from "../../utils/uuid";

type Props = TypeFromSelection<typeof singleImageAccordionSelection> & {
  colorway: Colorways;
};
export default function SingleImageAccordion({
  accordionItems,
  colorway = Colorways.Default,
  CTA,
  imageOrVideo,
  intro,
  sectionIntro,
  showIterator,
}: Props) {
  const { width, height } = getImageDimensions({
    ...imageOrVideo,
    asset: imageOrVideo.asset || null,
  });

  const widthCorrected = imageOrVideo.is2x ? width / 2 : width;
  const heightCorrected = imageOrVideo.is2x ? height / 2 : height;

  // accordion toggle
  /////
  const defaultAccordionItems = useMemo(
    () =>
      accordionItems.map((item) => ({
        ...item,
        _id: uuid(),
        _isExpanded: false,
      })),
    [accordionItems],
  );

  const [accItems, setAccItems] = useState(defaultAccordionItems);

  const toggle = useCallback(
    (accItem: AccordionItemType) => () => {
      setAccItems((prev) =>
        prev.map((prevAccItem) => ({
          ...prevAccItem,
          _isExpanded:
            prevAccItem._id === accItem._id
              ? !prevAccItem._isExpanded // toggle if it is the accordion I've clicked
              : false, // otherwise, close it
        })),
      );
    },
    [setAccItems],
  );

  return (
    <Block>
      <div className="grid grid-rows-[auto_1fr]">
        {sectionIntro?.shouldDisplay && (
          <SectionIntro
            colorway={colorway}
            heading={sectionIntro.heading}
            intro={sectionIntro.intro}
            overline={sectionIntro.overline}
            shouldDisplay={sectionIntro.shouldDisplay}
          />
        )}

        <div className="m-auto flex w-full flex-col lg:max-w-full lg:flex-row lg:items-start lg:space-x-28">
          <div className="align-center flex flex-col justify-start lg:w-[50%]">
            {!isEmpty(intro) && (
              <div className="mb-6 mt-8 text-center">
                <RichTextSimpleComponent content={intro} colorway={colorway} />
              </div>
            )}

            <div className="flex items-start justify-start">
              <Image
                width={widthCorrected}
                height={heightCorrected}
                style={{
                  margin: "0 auto",
                }}
                sizes="100vw"
                className={`max-w-full`}
                src={getImageURL(imageOrVideo).url()}
                alt="single image accordion"
                quality={90}
              />
            </div>
          </div>

          <div className="mt-8 lg:w-[50%] lg:flex-1">
            {accItems.map((accordion, i) => (
              <SingleAccordionItem
                body={accordion.body}
                colorway={colorway}
                index={i}
                key={i}
                title={accordion.title}
                showIterator={showIterator}
                toggle={toggle(accordion)}
                isExpanded={accordion._isExpanded}
              />
            ))}

            <div className="mt-4">
              <Footer colorway={colorway} CTA={CTA} />
            </div>
          </div>
        </div>
      </div>
    </Block>
  );
}

SingleImageAccordion.displayName = "SingleImageAccordion";
