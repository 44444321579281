export const Minus = ({ classNames }: { classNames?: string }) => (
  <svg
    className={classNames}
    width="24"
    height="4"
    viewBox="0 0 24 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22 0H14H10H2C0.895431 0 0 0.895431 0 2C0 3.10457 0.895431 4 2 4H10H14H22C23.1046 4 24 3.10457 24 2C24 0.895431 23.1046 0 22 0Z"
      fill="currentColor"
    />
  </svg>
);
