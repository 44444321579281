import { useMemo } from "react";
import { TypeFromSelection } from "groqd";
import { useWindowSize } from "@uidotdev/usehooks";
import { CarouselProvider } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

import { SlidingCarousel } from "./SlidingCarousel";
import { Colorways } from "../../theme";
import { CarouselAccordion } from "./CarouselAccordion";
import { carouselSelection } from "./query";

type CarouselProps = TypeFromSelection<typeof carouselSelection> & {
  colorway: Colorways;
};

function Carousel({
  carouselTabs,
  colorway,
  config,
  sectionIntro,
}: CarouselProps) {
  const width: number = useWindowSize().width || 0;

  // memos
  /////
  const displayAccordions = useMemo(() => width >= 0 && width <= 960, [width]);

  if (width === 0) return null;

  return (
    <CarouselProvider
      naturalSlideWidth={0}
      naturalSlideHeight={0}
      totalSlides={carouselTabs?.length}
      currentSlide={0}
      infinite={true}
      interval={config?.animationInterval}
      isPlaying={true}
    >
      {displayAccordions ? (
        <CarouselAccordion
          carouselTabs={carouselTabs}
          colorway={colorway}
          sectionIntro={sectionIntro}
        />
      ) : (
        <SlidingCarousel
          animationInterval={config?.animationInterval}
          carouselTabs={carouselTabs}
          colorway={colorway}
          sectionIntro={sectionIntro}
        />
      )}
    </CarouselProvider>
  );
}

function SSRCarousel(props: CarouselProps) {
  if (typeof window === "undefined") return null;
  return <Carousel {...props} />;
}

export default SSRCarousel;
