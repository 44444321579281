import { q } from "groqd";

import { imageSelection } from "@/selections/image-selection";
import { sanityInternalsSelection } from "@/selections/sanity-internals-selection";

export const ctaBannerSelection = {
  ...sanityInternalsSelection,
  image: q.object(imageSelection),
  heading: q.string(),
  body: q.string(),
};
